import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a160bd3 = () => interopDefault(import('../src/pages/campaigns.vue' /* webpackChunkName: "pages/campaigns" */))
const _1f2ac086 = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _1f4fcc89 = () => interopDefault(import('../src/pages/checkout-finished.vue' /* webpackChunkName: "pages/checkout-finished" */))
const _1114de10 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _52655f2b = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _a15da07c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4e149975 = () => interopDefault(import('../src/pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _78f8d2d9 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _199ade6c = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _76dbd1d4 = () => interopDefault(import('../src/pages/my-orders.vue' /* webpackChunkName: "pages/my-orders" */))
const _57eafb5e = () => interopDefault(import('../src/pages/fabrics.vue' /* webpackChunkName: "pages/fabrics" */))
const _379bb052 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _e84a48b4 = () => interopDefault(import('../src/pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _95d88404 = () => interopDefault(import('../src/pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _f8b4aeea = () => interopDefault(import('../src/pages/user.vue' /* webpackChunkName: "pages/user" */))
const _57d20416 = () => interopDefault(import('../src/pages/user/my-addresses.vue' /* webpackChunkName: "pages/user/my-addresses" */))
const _9df652aa = () => interopDefault(import('../src/pages/user/my-info.vue' /* webpackChunkName: "pages/user/my-info" */))
const _446b9332 = () => interopDefault(import('../src/pages/catalog/product/_pslug.vue' /* webpackChunkName: "pages/catalog/product/_pslug" */))
const _a23d552e = () => interopDefault(import('../src/pages/change-email/confirm/_token.vue' /* webpackChunkName: "pages/change-email/confirm/_token" */))
const _957840f6 = () => interopDefault(import('../src/pages/register/confirm/_token.vue' /* webpackChunkName: "pages/register/confirm/_token" */))
const _1a019039 = () => interopDefault(import('../src/pages/cart/_token.vue' /* webpackChunkName: "pages/cart/_token" */))
const _163478f2 = () => interopDefault(import('../src/pages/catalog/_slug/index.vue' /* webpackChunkName: "pages/catalog/_slug/index" */))
const _d4fdad4c = () => interopDefault(import('../src/pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _6f79b340 = () => interopDefault(import('../src/pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _4562091c = () => interopDefault(import('../src/pages/simulator/_slug/index.vue' /* webpackChunkName: "pages/simulator/_slug/index" */))
const _0227de7a = () => interopDefault(import('../src/pages/catalog/_slug/_pslug/index.vue' /* webpackChunkName: "pages/catalog/_slug/_pslug/index" */))
const _3df6f82f = () => interopDefault(import('../src/pages/simulator/_slug/_pslug/index.vue' /* webpackChunkName: "pages/simulator/_slug/_pslug/index" */))
const _586ce92a = () => interopDefault(import('../src/pages/simulator/_slug/_pslug/_ppslug/index.vue' /* webpackChunkName: "pages/simulator/_slug/_pslug/_ppslug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campanas",
    component: _7a160bd3,
    name: "campaigns___es"
  }, {
    path: "/checkout",
    component: _1f2ac086,
    name: "checkout___es"
  }, {
    path: "/checkout-finished",
    component: _1f4fcc89,
    name: "checkout-finished___es"
  }, {
    path: "/contacto",
    component: _1114de10,
    name: "contact___es"
  }, {
    path: "/cookie-policy",
    component: _52655f2b,
    name: "cookie-policy___es"
  }, {
    path: "/en",
    component: _a15da07c,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _4e149975,
    name: "forgot-password___es"
  }, {
    path: "/login",
    component: _78f8d2d9,
    name: "login___es"
  }, {
    path: "/logout",
    component: _199ade6c,
    name: "logout___es"
  }, {
    path: "/my-orders",
    component: _76dbd1d4,
    name: "my-orders___es"
  }, {
    path: "/nuestras-telas",
    component: _57eafb5e,
    name: "fabrics___es"
  }, {
    path: "/privacy-policy",
    component: _379bb052,
    name: "privacy-policy___es"
  }, {
    path: "/register",
    component: _e84a48b4,
    name: "register___es"
  }, {
    path: "/terms-conditions",
    component: _95d88404,
    name: "terms-conditions___es"
  }, {
    path: "/user",
    component: _f8b4aeea,
    name: "user___es",
    children: [{
      path: "my-addresses",
      component: _57d20416,
      name: "user-my-addresses___es"
    }, {
      path: "my-info",
      component: _9df652aa,
      name: "user-my-info___es"
    }]
  }, {
    path: "/en/campaigns",
    component: _7a160bd3,
    name: "campaigns___en"
  }, {
    path: "/en/checkout",
    component: _1f2ac086,
    name: "checkout___en"
  }, {
    path: "/en/checkout-finished",
    component: _1f4fcc89,
    name: "checkout-finished___en"
  }, {
    path: "/en/contact",
    component: _1114de10,
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _52655f2b,
    name: "cookie-policy___en"
  }, {
    path: "/en/forgot-password",
    component: _4e149975,
    name: "forgot-password___en"
  }, {
    path: "/en/login",
    component: _78f8d2d9,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _199ade6c,
    name: "logout___en"
  }, {
    path: "/en/my-orders",
    component: _76dbd1d4,
    name: "my-orders___en"
  }, {
    path: "/en/our-fabrics",
    component: _57eafb5e,
    name: "fabrics___en"
  }, {
    path: "/en/privacy-policy",
    component: _379bb052,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _e84a48b4,
    name: "register___en"
  }, {
    path: "/en/terms-conditions",
    component: _95d88404,
    name: "terms-conditions___en"
  }, {
    path: "/en/user",
    component: _f8b4aeea,
    name: "user___en",
    children: [{
      path: "my-addresses",
      component: _57d20416,
      name: "user-my-addresses___en"
    }, {
      path: "my-info",
      component: _9df652aa,
      name: "user-my-info___en"
    }]
  }, {
    path: "/en/catalog/product/:pslug?",
    component: _446b9332,
    name: "catalog-product-pslug___en"
  }, {
    path: "/en/change-email/confirm/:token?",
    component: _a23d552e,
    name: "change-email-confirm-token___en"
  }, {
    path: "/en/register/confirm/:token?",
    component: _957840f6,
    name: "register-confirm-token___en"
  }, {
    path: "/catalogo/producto/:pslug?",
    component: _446b9332,
    name: "catalog-product-pslug___es"
  }, {
    path: "/change-email/confirm/:token?",
    component: _a23d552e,
    name: "change-email-confirm-token___es"
  }, {
    path: "/en/cart/:token?",
    component: _1a019039,
    name: "cart-token___en"
  }, {
    path: "/en/catalog/:slug",
    component: _163478f2,
    name: "catalog-slug___en"
  }, {
    path: "/en/product/:slug?",
    component: _d4fdad4c,
    name: "product-slug___en"
  }, {
    path: "/en/reset-password/:token?",
    component: _6f79b340,
    name: "reset-password-token___en"
  }, {
    path: "/en/simulator/:slug",
    component: _4562091c,
    name: "simulator-slug___en"
  }, {
    path: "/register/confirm/:token?",
    component: _957840f6,
    name: "register-confirm-token___es"
  }, {
    path: "/en/catalog/:slug/:pslug",
    component: _0227de7a,
    name: "catalog-slug-pslug___en"
  }, {
    path: "/en/simulator/:slug/:pslug",
    component: _3df6f82f,
    name: "simulator-slug-pslug___en"
  }, {
    path: "/en/simulator/:slug/:pslug/:ppslug",
    component: _586ce92a,
    name: "simulator-slug-pslug-ppslug___en"
  }, {
    path: "/cart/:token?",
    component: _1a019039,
    name: "cart-token___es"
  }, {
    path: "/catalogo/:slug",
    component: _163478f2,
    name: "catalog-slug___es"
  }, {
    path: "/producto/:slug?",
    component: _d4fdad4c,
    name: "product-slug___es"
  }, {
    path: "/reset-password/:token?",
    component: _6f79b340,
    name: "reset-password-token___es"
  }, {
    path: "/simulador/:slug",
    component: _4562091c,
    name: "simulator-slug___es"
  }, {
    path: "/catalogo/:slug/:pslug",
    component: _0227de7a,
    name: "catalog-slug-pslug___es"
  }, {
    path: "/simulador/:slug/:pslug",
    component: _3df6f82f,
    name: "simulator-slug-pslug___es"
  }, {
    path: "/simulador/:slug/:pslug/:ppslug",
    component: _586ce92a,
    name: "simulator-slug-pslug-ppslug___es"
  }, {
    path: "/",
    component: _a15da07c,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
